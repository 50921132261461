<template>
  <div v-wechat-title="title">
    <div v-show="active === 'home'" class="margin-bottom100">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in tabledata.banner" :key="index">
          <img style="width:100%;height:200px" :src="baseSrc + item">
        </van-swipe-item>
      </van-swipe>

      <div class="topbox boxshadow">
        <div>
          <div class="toptitle bg-cyan">
            <div>
              <div>{{ tabledata.player_count }}</div>
              <div>参赛选手</div>
            </div>
            <div>
              <div>{{ tabledata.votes }}</div>
              <div>累计投票</div>
            </div>
            <div>
              <div>{{ tabledata.views }}</div>
              <div>累计访问</div>
            </div>
          </div>
        </div>
        <div v-if="tabledata.vote_end === 1" class="padding-sm">
          <div class="text-center margin-top-sm text-bold">活动已结束</div>
        </div>

        <div class="margin-top boxshadow padding-sm">
          <div>
            报名开始：{{ tabledata.sign_up_start }}
          </div>
          <div>
            报名结束：{{ tabledata.sign_up_end }}
          </div>
          <div>
            活动开始：{{ tabledata.start_date }}
          </div>
          <div>
            活动结束：{{ tabledata.end_date }}
          </div>
          <div class="flex">
            <div class="whitespace">
              活动规则：
            </div>
            <div v-html="tabledata.rule" />
          </div>
        </div>

        <div class="margin-top grid col-2">
          <div v-for="(itm,idx) in tabledata.player_data" :key="idx" class="text-center margin-bottom">
            <div class="boxshadow" @click="jumptodetail(itm)">
              <img
                style="width: 140px;height: 140px;"
                :src="itm.show_pic ? baseSrc + itm.show_pic.split(',')[0] : '' "
              >
              <div class="margin-top-sm">
                {{ idx+1 }}号:{{ itm.player_name }}
              </div>
              <div class="margin-top-sm">
                {{ itm.votes }}票
              </div>
              <div class="padding flex  flex-direction">
                <van-button type="info" size="normal" @click.stop="submitreal(itm.id)">投票</van-button>
              </div>
              <div class="padding-lr padding-bottom flex flex-direction margin-top-xs">
                <van-button type="default" size="normal" @click.stop="jumptodetail(itm)">详情</van-button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div v-show="active === 'desc'" class="margin-bottom100">
      <div class="topbox boxshadow">
        <div class="bg-cyan topintroduce">活动说明</div>
        <div class="padding-sm">
          <div class="detailtitle">{{ tabledata.launch_org }}</div>
          <div class="detailtitle margin-top-lg">报名时间</div>
          <div class="text-center margin-top-sm">活动开始：{{ tabledata.sign_up_start }}</div>
          <div class="text-center margin-top-sm">活动结束：{{ tabledata.sign_up_end }}</div>
          <div class="detailtitle margin-top-lg">投票时间</div>
          <div class="text-center margin-top-sm">活动开始：{{ tabledata.start_date }}</div>
          <div class="text-center margin-top-sm">活动结束：{{ tabledata.end_date }}</div>
          <div class="detailtitle">
            活动规则：
          </div>
          <div class="margin-top-sm">
            <div v-html="tabledata.rule" />
          </div>
          <div class="detailtitle">
            奖项设计：
          </div>
          <div class="margin-top-sm">
            <div v-html="tabledata.award_design" />
          </div>
          <div class="margin-top-xl">
            本次活动由{{ tabledata.title }}和深圳聚梦创远科技有限公司联合监控，所有排名由获取的投票数量决定，对恶意刷票行为进行监控，一经发现，将会取消参赛资格。
          </div>
        </div>
      </div>
    </div>
    <div v-show="active === 'bar'" class="margin-bottom100">
      <div class="topbox boxshadow">
        <div>
          <div class="toptitle bg-cyan">
            <div>
              <div>{{ tabledata.player_count }}</div>
              <div>参赛选手</div>
            </div>
            <div>
              <div>{{ tabledata.votes }}</div>
              <div>累计投票</div>
            </div>
            <div>
              <div>{{ tabledata.views }}</div>
              <div>累计访问</div>
            </div>
          </div>
        </div>
        <div v-if="tabledata.vote_end === 0" class="padding-sm">
          <div class="text-center margin-top-sm">报名开始：{{ tabledata.sign_up_start }}</div>
          <div class="text-center margin-top-sm">报名结束：{{ tabledata.sign_up_end }}</div>
          <div class="text-center margin-top-sm">活动开始：{{ tabledata.start_date }}</div>
          <div class="text-center margin-top-sm">活动结束：{{ tabledata.end_date }}</div>
        </div>
        <div v-if="tabledata.vote_end === 1" class="padding-sm">
          <div class="text-center margin-top-sm text-bold">活动已结束</div>
        </div>
        <div class="toptitle bg-cyan margin-top">
          <div>
            <div>排名</div>
          </div>
          <div>
            <div>参赛选手</div>
          </div>
          <div>
            <div>票数</div>
          </div>
        </div>
        <div v-for="(iem,inx) in ranklist" :key="inx">
          <div class="flex text-bold rankpeople">
            <div style="white-space: nowrap;">
              第{{ inx+1 }}名
            </div>
            <div class="margin-left wordspace">
              <div
                v-if="iem.show_pic"
                class="cu-avatar round lg margin-right-sm"
                :style="[{ backgroundImage:'url(' + baseSrc + iem.show_pic.split(',')[0] + ')' }]"
              />
              {{ iem.player_name }}
            </div>
            <div style="margin-left: auto;">
              {{ iem.votes }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomcompany">
      © 2022 深圳聚梦创远科技有限公司
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item name="home" icon="wap-home-o">首页</van-tabbar-item>
      <van-tabbar-item name="desc" icon="description">详情</van-tabbar-item>
      <van-tabbar-item name="bar" icon="bar-chart-o" @click="rankpeoplelist">排名</van-tabbar-item>
    </van-tabbar>

    <van-dialog v-model="show" title="关注公众号" message-align="center" @confirm="resintpage">
      <img class="imgpic" src="http://static.dreamacro.com/publicpic/gzh">
      <!-- <img class="imgpic" src="../../utils/1.jpg"> -->
    </van-dialog>
  </div>
</template>

<script>
import { getvoteappindex, getvoteapprank, postvoteappreal } from '@/api/votemange.js'
export default {
  data() {
    return {
      show: false,
      title: '',
      id: '',
      originid: '',
      active: 'home',
      tabledata: [],
      ranklist: [],
      windowWidth: document.documentElement.clientWidth, // 实时屏幕宽度
      baseSrc: 'http://cdn.dreamacro.com/',
      code: '',
      openid: ''
    }
  },

  mounted() {
    // localStorage.setItem('openid', '')
    // localStorage.setItem('code', '')
    const openid = localStorage.getItem('openid')
    const code = localStorage.getItem('code')
    this.openid = openid
    if (window.location.search.slice(1)) {
      const link = window.location.search.slice(1).split('&')
      this.title = decodeURIComponent(link[2].split('=')[1])
      this.originid = decodeURIComponent(link[1].split('=')[1])
      this.id = decodeURIComponent(link[0].split('=')[1])
    }

    if (openid) {
      this.upadatapage(code)
    } else {
      this.init()
    }
  },
  methods: {
    async init() {
      this.code = ''
      // var appid = 'wxa9b1061989663c5c' // 公众号
      var appid = 'wx79d1a256e0353320' // 线上公众号
      var local = window.location.href
      this.code = this.getUrlCode().code
      if (this.code === null || this.code === '' || this.code === undefined) {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=ok#wechat_redirect'
      } else {
        const res = await getvoteappindex(this.originid, this.code, 'office')
        if (res.code === 0) {
          res.data.banner = res.data.banner.split(',')
          this.tabledata = res.data
          localStorage.setItem('openid', res.data.open_id)
          this.openid = res.data.open_id
          localStorage.setItem('code', this.code)
          if (this.id !== '0') {
            setTimeout(() => {
              this.$router.push({ name: 'Sharevotepage', query: { id: this.id, originid: this.originid }})
            }, 2000)
          }
        } else {
          this.code = ''
          localStorage.setItem('code', '')
          this.init()
        }
      }
    },
    async upadatapage(code) {
      const res = await getvoteappindex(this.originid, code, 'office', this.openid)
      if (res.code === 0) {
        res.data.banner = res.data.banner.split(',')
        this.tabledata = res.data
        if (res.data.open_id) {
          this.openid = res.data.open_id
          localStorage.setItem('openid', res.data.open_id)
        }
        if (this.id !== '0') {
          setTimeout(() => {
            this.$router.push({ name: 'Sharevotepage', query: { id: this.id, originid: this.originid }})
          }, 2000)
        }
      } else {
        this.code = ''
        localStorage.setItem('code', '')
        this.init()
      }
    },
    async rankpeoplelist() {
      const res = await getvoteapprank(this.tabledata.id)
      this.ranklist = res.data
    },
    getUrlCode() { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      // eslint-disable-next-line no-new-object
      var theRequest = new Object()
      if (url.indexOf('?') !== -1) {
        var str = url.substr(1)
        var strs = str.split('&')
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
        }
      }
      return theRequest
    },
    async submitreal(id) {
      // if (this.openid === '' || this.openid === null || this.openid === undefined) {
      //   this.show = true
      // } else {
      const res = await postvoteappreal(id, this.openid)
      if (res.code === 0) {
        this.$toast.success('投票成功')
        this.upadatapage()
      } else {
        if (res.msg === '未关注公众号') {
          this.show = true
        } else {
          this.$toast.success(res.msg)
        }
      }
    },
    resintpage() {
      this.init()
    },
    jumptodetail(val) {
      if (this.openid) {
        this.$router.push({ name: 'Sharevotepage', query: { id: val.id, originid: this.originid }})
      }
    }
  }
}
</script>

<style scoped>
	.topbox {
		margin: 20px;
	}
  .toptitle {
		color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		text-align: center;
		padding: 15px;
	}
  .bg-cyan{
    background-color: #1cbbb4;
  }
  .text-center{
    text-align: center;
  }
  .padding-sm{
        padding: 10px;
  }
  .margin-top-sm {
    margin-top: 10px;
}
.text-bold {
    font-weight: bold;
}
.boxshadow {
    box-shadow: 0px 0px 8px 0px rgba(0, 19, 64, 0.1);
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
}
.margin-top {
    margin-top: 15px;
}
	.topintroduce {
		color: #FFFFFF;
		text-align: center;
		padding: 18px;
	}
  .detailtitle {
		text-align: center;
		font-size: 16px;
		font-weight: bold;
		margin-top: 10px;
	}
  .margin-top-lg {
    margin-top: 20px;
}
.margin-top-sm {
    margin-top: 10px;
}
.margin-top-xl {
    margin-top: 25px;
}
.margin-bottom100{
  margin-bottom:100px
}
.flex{
  display:flex
}
	.rankpeople {
		align-items: center;
		text-align: center;
		padding: 18px;
	}
  .wordspace{
		width: 230px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;
	}
  .cu-avatar.lg {
    width: 48px;
    height: 48px;
    font-size: 2em;
}
.cu-avatar {
    font-variant: small-caps;
    margin: 0;
    padding: 0;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #ccc;
    color: #ffffff;
    white-space: nowrap;
    position: relative;
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: center;
    vertical-align: middle;
    font-size: 1.5em;
}
.round {
    border-radius: 2500px;
}
.margin-left{
  margin-left: 15px;
}
	.bottomcompany {
		padding-bottom: 65px;
		text-align: center;
	}
  .grid {
    display: flex;
    flex-wrap: wrap;
}
.grid.col-2>div {
    width: 50%;
}
.margin-bottom {
    margin-bottom: 15px;
}
.padding{
  padding: 15px;
}
.padding-lr {
    padding-left: 15px;
    padding-right: 15px;
}
.padding-bottom {
    padding-bottom: 15px;
}
.margin-top-xs {
    margin-top: 5px;
}
.flex-direction {
    flex-direction: column;
}
.flex {
    display: flex;
}
.imgpic{
  margin: auto;display: flex; width:200px;height:200px
}
</style>
